<template>
  <moe-page title="赠品管理">
    <moe-form :showBack="false">
      <moe-describe-list title="" :col="1">
        <moe-describe-item label="赠品名称">{{ giftData.name }}</moe-describe-item>
        <moe-describe-item label="领取有效期">{{ `${giftData.validDays}天` }}</moe-describe-item>
      </moe-describe-list>
    </moe-form>

    <moe-table
      :stripe="true"
      :numberShow="false"
      :data="giftData.goodsList"
      :mode="false"
      :showPage="false">
      <el-table-column label="赠品信息" min-width="200">
        <template slot-scope="{ row }">
          <div class="df aic jcc">
            <moe-image :src="row.coverUrl" width="80px" height="80px" />
            <div class="df1 tal ml-10">
              <p class="fwb">{{ row.name }}</p>
              <p class="font-12 color-info">商品编号: {{ row.id }}</p>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="活动规格" min-width="200">
        <div class="df aic jcc" slot-scope="{ row }">
          <div class="df aic mr-5" v-for="(item, index) in row.specList" :key="index">
            <moe-tag class="mr-5" v-for="(specItem, specIndex) in item.specValueStr.split(',')" :key="specIndex">{{ specItem }}</moe-tag>
          </div>
        </div>
      </el-table-column>

      <el-table-column label="活动剩余库存" prop="stockLeft" min-width="120" />
      <el-table-column label="活动总库存" prop="stockTotal" min-width="120" />
    </moe-table>

    <moe-form :showBack="false" :showClose="true"></moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGiftManageList',
  data() {
    return {
      giftData: {},
    }
  },
  methods: {
    /** 获取赠品活动详情 */
    req_getGiftDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GIFTMANAGE_API.getGiftDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            this.giftData = {
              ...data.result,
              goodsList: [],
            }

            this.req_queryShopGoodsById();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 查询商品详情 */
    req_queryShopGoodsById() {
      const { stockTotal, stockLeft, goodsId, goodsItemId } = this.giftData;
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: goodsId }).then((data) => {
        if (data.code === 200) {
          this.giftData.goodsList = [{
            ...data.result,
            specList: data.result.goodsItem.filter((specItem) => specItem.id === goodsItemId).map((specItem) => {
              return {
                ...specItem,
                stockTotal,
              }
            }),
            stockTotal,
            stockLeft
          }]
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.req_getGiftDetail();
  }
}
</script>

<style></style>